<template>
  <tr>
    <td>
      <DateFormat :value="item.date" data-testid="bgm-date" />
    </td>
    <td colspan="3">
      <v-chip
        label
        small
        color="#EBF2FB"
        text-color="#333"
        class="width-100-pct align-center justify-center"
        data-testid="bgm-pending"
      >
        Pending
      </v-chip>
    </td>
    <td v-if="!hideAudit"></td>
  </tr>
</template>

<script>
import DateFormat from '@/components/DateFormat'
export default {
  name: 'SmbgTableMissingRow',
  components: { DateFormat },
  props: {
    item: { type: Object, required: true },
    hideAudit: { type: Boolean, required: false, default: false },
  },
}
</script>
